import DataService from "./dataService";
import config from "../common/config";
import { getPagingLimit } from "../common/storage";
import { getByPageOrder } from "../common/functions";

class ApiUser {
    getPaging(paging, orders) {
        const limit = getPagingLimit();
        return DataService.get(`/admins?${ getByPageOrder(paging, orders, limit ? limit : config.PER_PAGE) }`);
    }
    get(id) {
        return DataService.get(`/admin_detail?id=${id}`);
    }
    addOrUpdate(data) {
        return DataService.post("/admins", data);
    }
    delete(id) {
        return DataService.delete(`/admins?id=${id}`);
    }
    changePass(data) {
        return DataService.post("/password", data);
    }
}

export default new ApiUser();
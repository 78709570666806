<template>
  <div id="module-user">
    <div class="card shadow">
      <div class="card-body p-0">
        <table class="table size-website">
          <thead>
            <tr>
              <th>
                <Order title="Tài khoản" column="username" :callBack="orderBy" :orders="orders" />
              </th>
              <th>
                <Order title="Tên" column="first_name" :callBack="orderBy" :orders="orders" />
              </th>
              <th>
                <Order title="Vai trò" column="role" :callBack="orderBy" :orders="orders" />
              </th>
              <th>
                <Order title="Địa chỉ" column="province_id" :callBack="orderBy" :orders="orders" />
              </th>
              <th>
                <Order title="Đăng nhập cuối" column="last_login" :callBack="orderBy" :orders="orders" />
              </th>
              <th>
                <Order title="Ngày tạo" column="created_at" :callBack="orderBy" :orders="orders" />
              </th>
              <th style="width: 108.25px;">
                <button class="btn btn-success btn-sm btn-block" v-on:click="addItem()">
                  <i class="fas fa-plus-circle"></i>
                </button>
              </th>
            </tr>
          </thead>
          <tbody v-if="items && items.items">
            <tr v-for="(item, index) in items.items" :key="index">
              <td class="pl-4">{{ item.username }}</td>
              <td>{{ item.first_name }} {{ item.last_name }}</td>
              <td>
                <span v-if="item.role">{{ getTextOption(item.role, roleOptions) }}</span>
              </td>
              <td>{{ item.address }}</td>
              <td class="text-center">{{ formatDate(item.last_login) }}</td>
              <td class="text-center">{{ formatDate(item.created_at) }}</td>
              <td>
                <div class="btn-group">
                  <button class="btn btn-info btn-sm" v-on:click="updateItem(item)"><i class="fas fa-edit"></i></button>
                  <button class="btn btn-outline-info btn-sm" v-on:click="changePass(item)"><i
                      class="fas fa-lock"></i></button>
                  <button class="btn btn-default btn-sm" v-on:click="deleteItem(item)"><i
                      class="far fa-trash-alt"></i></button>
                </div>
              </td>
            </tr>
          </tbody>
          <Paging v-if="items" :items="items" column="8" :callBack="pagingBy" />
        </table>
        <table class="table size-mobile">
          <thead>
            <tr>
              <th style="width: 79px;">
                <button class="btn btn-success btn-sm btn-block" v-on:click="addItem()">
                  <i class="fas fa-plus-circle"></i>
                </button>
              </th>
              <th>
                <Order title="Quản trị viên" column="username" :callBack="orderBy" :orders="orders" />
              </th>
            </tr>
          </thead>
          <tbody v-if="items && items.items">
            <tr v-for="(item, index) in items.items" :key="index">
              <td>
                <div class="btn-group mb-2">
                  <button class="btn btn-info btn-sm" v-on:click="updateItem(item)"><i class="fas fa-edit"></i></button>
                  <button class="btn btn-default btn-sm" v-on:click="deleteItem(item)"><i
                      class="far fa-trash-alt"></i></button>
                </div>
                <button class="btn btn-outline-info btn-sm btn-block" v-on:click="changePass(item)"><i
                    class="fas fa-lock"></i></button>
              </td>
              <td>
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <div class="text-bold"><i class="fas fa-user mr-2"></i>{{ item.username }}</div>
                    <div>{{ item.first_name }} {{ item.last_name }}
                      <span class="text-sm ml-2" v-if="item.role">({{ getTextOption(item.role, roleOptions) }})</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div><i class="text-sm">{{ item.address }}</i></div>
                    <div><span class="text-sm">{{ formatDate(item.last_login) }}</span></div>
                    <!-- <div><span class="text-sm">{{ formatDate(item.created_at) }}</span></div> -->
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <Paging v-if="items" :items="items" column="8" :callBack="pagingBy" />
        </table>
      </div>
    </div>

    <b-modal centered scrollable no-close-on-backdrop :id="modalIdUser"
      :title="itemChange && itemChange.id ? 'Cập nhật' : 'Thêm mới'">
      <div class="row admin-page" v-if="itemChange">
        <div class="col-sm-6" v-if="!itemChange.id">
          <div class="form-group">
            <label class="control-label text-semibold label-valid">Tài khoản</label>
            <input type="text" class="form-control" v-model="itemChange.username" id="username" placeholder="Tài khoản"
              autocomplete="off">
          </div>
        </div>
        <div class="col-sm-6" v-if="!itemChange.id">
          <div class="form-group">
            <label class="control-label text-semibold label-valid">Mật khẩu</label>
            <input type="password" class="form-control" v-model="itemChange.password" id="password"
              placeholder="Mật khẩu" autocomplete="off">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="control-label text-semibold label-valid">Họ & đệm</label>
            <input type="text" class="form-control" v-model="itemChange.first_name" id="first_name"
              placeholder="Họ & đệm" autocomplete="off">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="control-label text-semibold label-valid">Tên</label>
            <input type="text" class="form-control" v-model="itemChange.last_name" id="last_name" placeholder="Tên"
              autocomplete="off">
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group mb-0">
            <label class="control-label text-semibold label-valid">Địa chỉ</label>
            <InputAddress :province="itemChange.province_id" :district="itemChange.district_id"
              :ward="itemChange.ward_id" :street="itemChange.street" :callBack="setAddress" />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="control-label text-semibold label-valid">Vai trò</label>
            <b-form-select v-model="itemChange.role" id="role" :options="roleOptions"></b-form-select>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button class="btn btn-info btn-sm" @click="saveItem(itemChange)">
          <i class="fas fa-save mr-2"></i><span>Lưu</span>
        </button>
        <button class="btn btn-default btn-sm" @click="hideModal(modalIdUser)">
          <i class="far fa-window-close mr-2"></i><span>Hủy</span>
        </button>
      </template>
    </b-modal>

    <b-modal centered scrollable no-close-on-backdrop :id="modalIdChangePass" title="Thay đổi mật khẩu">
      <div class="row" v-if="itemChangePass">
        <div class="col-sm-6">
          <div class="form-group">
            <label class="control-label text-semibold">Tài khoản</label>
            <div class="form-control-text">{{ itemChangePass.username }}</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="control-label text-semibold label-valid">Mật khẩu mới</label>
            <input type="password" class="form-control" v-model="itemChangePass.new_password" id="new_password"
              placeholder="Mật khẩu mới" autocomplete="off">
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button class="btn btn-info btn-sm" @click="saveItemPass(itemChangePass)">
          <i class="fas fa-save mr-2"></i><span>Lưu</span>
        </button>
        <button class="btn btn-default btn-sm" @click="hideModal(modalIdChangePass)">
          <i class="far fa-window-close mr-2"></i><span>Hủy</span>
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ApiUser from "../../services/apiUser";
import { roleOptions } from "../../common/constants";

export default {
  components: {
    Paging: () => import("../components/Paging.vue"),
    Order: () => import("../components/Order.vue"),
    InputAddress: () => import("../components/InputAddress.vue"),
  },
  data() {
    return {
      items: null,
      paging: null,
      orders: [{ column: "created_at", type: "desc" }],
      modalIdUser: "user-modal",
      modalIdChangePass: "changepass-modal",
      itemChange: null,
      itemChangePass: null,
      roleOptions: roleOptions
    };
  },
  methods: {
    async initItems() {
      this.showLoading(true);
      const data = await ApiUser.getPaging(this.paging, this.orders);
      this.showLoading(false);
      if (data && data.items) {
        this.items = data;
        this.items.items = data.items.map(e => {
          if (e.address) e.address = e.address.substring(e.address.lastIndexOf(",") + 1);
          return e;
        });
      }
    },
    pagingBy(paging) {
      this.paging = paging;
      this.initItems();
    },
    orderBy(orders) {
      this.orders = orders;
      this.initItems();
    },
    setAddress(p, d, w, s, a) {
      this.itemChange.province_id = p;
      this.itemChange.district_id = d;
      this.itemChange.ward_id = w;
      this.itemChange.street = s;
      this.itemChange.address = a;
    },
    makeItem() {
      return { id: "", first_name: "", last_name: "", role: "1", province_id: "", district_id: "", ward_id: "", street: "", address: "" };
    },
    addItem() {
      this.itemChange = this.makeItem();
      this.showModal(this.modalIdUser);
    },
    async updateItem(item) {
      this.itemChange = this.setObjectValue(this.makeItem(), item);
      this.showModal(this.modalIdUser);
    },
    checkValid(item) {
      console.log("item", item);
      if (item) return this.checkValidForm([
        { id: "username", condition: (!item.id && item.username) || item.id, message: 'Vui lòng nhập Tài khoản' },
        { id: "password", condition: (!item.id && item.password) || item.id, message: 'Vui lòng nhập Mật khẩu' },
        { id: "first_name", value: item.first_name, message: 'Vui lòng nhập Họ & đệm' },
        { id: "last_name", value: item.last_name, message: 'Vui lòng nhập Tên' },
        { id: "province", value: item.province_id, message: 'Vui lòng nhập Tỉnh/Thành phố' },
        { id: "role", value: item.role, message: 'Vui lòng nhập Vai trò' }
      ]);
      return false;
    },
    async saveItem(item) {
      if (this.checkValid(item)) {
        this.showLoading(true);
        const data = await ApiUser.addOrUpdate(item);
        this.showLoading(false);
        this.checkResponse(data, () => {
          this.hideModal(this.modalIdUser);
          this.initItems();
        });
      }
    },
    async deleteItem(item) {
      if (!item.id) return;
      this.confirmDelete(this.$route.name, item.username).then(async (result) => {
        if (!result) return;
        this.showLoading(true);
        const data = await ApiUser.delete(item.id);
        this.showLoading(false);
        this.checkResponse(data, () => {
          this.initItems();
        });
      })
    },
    async changePass(item) {
      this.itemChangePass = this.setObjectValue({ id: "", username: "", new_password: "" }, item);
      this.showModal(this.modalIdChangePass);
    },
    checkValidPass(item) {
      if (item) return this.checkValidForm([
        { id: "new_password", condition: item.new_password, message: 'Vui lòng nhập Mật khẩu mới' }
      ]);
      return false;
    },
    async saveItemPass(item) {
      if (this.checkValidPass(item)) {
        this.showLoading(true);
        const data = await ApiUser.changePass({ id: item.id, new_password: item.new_password });
        this.showLoading(false);
        this.checkResponse(data, () => {
          this.hideModal(this.modalIdChangePass);
          this.notifyToast('Success', 'success');
        });
      }
    },
  },
  mounted() {
    if (this.isAdmin()) this.initItems();
    else this.$router.go(-1);
  }
};
</script>

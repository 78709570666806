 const roleOptions = [
     { value: "1", text: "Admin" },
     { value: "2", text: "Operator" }
 ];
 const dayInWeekOptions = [
     { value: "1", text: "Chủ nhật" },
     { value: "2", text: "Thứ hai" },
     { value: "3", text: "Thứ ba" },
     { value: "4", text: "Thứ tư" },
     { value: "5", text: "Thứ năm" },
     { value: "6", text: "Thứ sáu" },
     { value: "7", text: "Thứ bảy" }
 ];
 const statusPayOptions = [
     { value: "0", text: "Nợ học phí" },
     { value: "1", text: "Đã đóng" }
 ];
 const statusStudentOptions = [
     { value: "0", text: "Đang học" },
     { value: "1", text: "Đã nghỉ học" }
 ];
 const statusTeacherOptions = [
    { value: "0", text: "Đang làm" },
    { value: "1", text: "Đã nghỉ việc" }
];
 export { roleOptions, dayInWeekOptions, statusPayOptions, statusStudentOptions, statusTeacherOptions };